import { Box, Divider, MenuItem, Popover, PopoverProps, Typography } from '@material-ui/core'
import { ReactComponent as DownloadPayHistoryIcon } from 'assets/svg/DownloadPayHistoryIcon.svg'
import { ReactComponent as EmailPayOffLetterIcon } from 'assets/svg/EmailPayOffLetterIcon.svg'
import { ReactComponent as PrepFundingEmailIcon } from 'assets/svg/PrepFundingEmailIcon.svg'
import { ReactComponent as ReadyToFundIcon } from 'assets/svg/ReadyToFundIcon.svg'
import { ReactComponent as REFIIcon } from 'assets/svg/REFIIcon.svg'
import { ReactComponent as CommentsLogIcon } from 'assets/svg/CommentsLogIcon.svg'
import { ReactComponent as AuditLogIcon } from 'assets/svg/AuditLogIcon.svg'
import { ReactComponent as ExtendIcon } from 'assets/svg/ExtendDealIcon.svg'
import { ReactComponent as EmailPayHistoryIcon } from 'assets/svg/EmailPayHistoryIcon.svg'
import { ReactComponent as ReturnIcon } from 'assets/svg/ReturnIcon.svg'
import { ReactComponent as DocumentIconOutlined } from 'assets/svg/DocumentIconOutlined.svg'
import React, { useCallback, useMemo, useState } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { DEAL_AUDIT_LOG_URL, DEAL_COMMENTS_LOG_URL, INHOUSE_DEAL } from 'constants/routes'
import { usePermission } from 'hooks/usePermission'
import {
  DEAL_AUDIT_LOG_PERM,
  DEAL_COMMENTS_LOG_PERM,
  DEAL_DOWNLOAD_PAY_HISTORY_PERM,
  DEAL_EMAIL_PAY_HISTORY_PERM,
  DEAL_EMAIL_PAYOFF_LETTER_PERM,
  DEAL_PREP_FUNDING_EMAIL_PERM,
  DEAL_READY_TO_FUND_PERM,
  DEAL_REFI_PERM,
  DEAL_PAY_HISTORY_DETAILS_CONDENSED,
  APPLY_COMMISSION_PERM,
  APPLY_PSF_PERM,
  DEAL_EXTEND_PERM,
  DEAL_CLAWBACK_PERM,
  MOVE_TO_DOCUSIGN,
} from 'constants/permissions'
import { useMutation, useQueryCache } from 'react-query'
import {
  emailPayOffLetter,
  extendDeal,
  refiDeal,
  sendPayHistoryToEmail,
  sendPrepFundingEmail,
  moveToDocusign,
  sendReadyToFundEmail,
  repClawback,
  isoClawback,
  getRefiDeals,
  zeroBalanceLetter,
  returnIsoClawback,
  returnRepClawback,
  getReverseSchedulesByDeal,
} from 'services/deals'
import { exportDealPayHistory, exportDealPayHistoryCondensed } from 'services/export'
import { downloadFile } from 'utils/downloadFile'
import { useSnackbar } from 'notistack'
import { SOMETHING_WENT_WRONG } from 'constants/errors'
import { IInhouseDealResponse, ILightDeal } from 'typescript/interfaces/deals'
import { DealsStatus, DealsType, ExtendDealType } from 'typescript/enums/deals'
import { usePopoverStyles } from 'containers/Deal/DealInfo/style'
import { applyCommission, applyPsf } from 'services/transactions'
import { AxiosError } from 'axios'
import ModalComponent from 'UI/Modal'
import { ExtendDealForm } from 'components/ExtendDealForm'
import { SelectRefiDealsForm } from 'components/SelectRefiDealsForm'

interface Props extends PopoverProps {
  deal?: IInhouseDealResponse
  refetch?: () => unknown
}

const topBlockPerms = [
  DEAL_DOWNLOAD_PAY_HISTORY_PERM,
  DEAL_EMAIL_PAY_HISTORY_PERM,
  DEAL_EMAIL_PAYOFF_LETTER_PERM,
  DEAL_PREP_FUNDING_EMAIL_PERM,
  DEAL_READY_TO_FUND_PERM,
  DEAL_REFI_PERM,
]

const DetailsPopover = ({ anchorEl, open, onClose, deal, refetch, ...rest }: Props) => {
  const { enqueueSnackbar } = useSnackbar()
  const [openExtendDeal, setOpenExtendDeal] = useState(false)
  const [openSelectRefiDeals, setOpenSelectRefiDeals] = useState<ILightDeal[]>([])
  const { hasPermission } = usePermission()
  const s = usePopoverStyles()
  const { push } = useHistory()
  const { id } = useParams<{ id: string }>()
  const queryCache = useQueryCache()

  const [moveToDocusignLoading, setMoveToDocusignLoading] = useState<boolean>(false)

  const disableEmailPayHistory = useMemo(() => {
    return deal?.status !== DealsStatus.approved || !hasPermission(DEAL_EMAIL_PAY_HISTORY_PERM)
  }, [deal])

  const disableDownloadPayHistory = useMemo(() => {
    return deal?.status !== DealsStatus.approved || !hasPermission(DEAL_DOWNLOAD_PAY_HISTORY_PERM)
  }, [deal])

  const disableDownloadPayHistoryCondensed = useMemo(() => {
    return deal?.status !== DealsStatus.approved || !hasPermission(DEAL_PAY_HISTORY_DETAILS_CONDENSED)
  }, [deal])

  const disableEmailPayOff = useMemo(() => {
    return deal?.type !== DealsType.inhouse || deal?.paidStatus === 'FullyPaid'
  }, [deal])

  const disableZeroBalance = useMemo(() => {
    return deal?.type !== DealsType.inhouse || deal?.paidStatus !== 'FullyPaid'
  }, [deal])

  const disablePrepFundingEmail = useMemo(() => {
    return (
      (deal?.status !== DealsStatus.new && deal?.status !== DealsStatus.inUnderwriting && deal?.status !== DealsStatus.readyToFund) ||
      !deal?.isSubmitted
    )
  }, [deal])

  const disableReadyToFund = useMemo(() => {
    return deal?.status !== DealsStatus.inUnderwriting && deal?.status !== DealsStatus.readyToFund
  }, [deal])

  const disableRefi = useMemo(() => {
    return deal?.status !== DealsStatus.approved || deal?.paidStatus === 'FullyPaid' || deal?.paidStatus === 'ProcessingRenewal'
  }, [deal])

  const disableExtend = useMemo(() => {
    return deal?.status !== DealsStatus.approved || deal?.flexAmount === undefined || deal?.flexAmount === null
  }, [deal])

  const handleClosePopover = useCallback(() => {
    // @ts-ignore
    onClose(new Event('click'), 'backdropClick')
  }, [])

  const disableClawback = useMemo(() => {
    return (deal?.status !== DealsStatus.approved && deal?.status !== DealsStatus.outhouseApproved) || !deal?.paymentInfo?.commissionToRep
  }, [deal])

  const disableIsoClawback = useMemo(() => {
    return (deal?.status !== DealsStatus.approved && deal?.status !== DealsStatus.outhouseApproved) || !deal?.iso
  }, [deal])

  const disableMoveToDocusign = useMemo(() => deal?.type !== DealsType.inhouse, [deal])

  const [applyCommissionMut, { isLoading: isLoadingApplyCommission }] = useMutation(applyCommission, {
    onSuccess: () => {
      handleClosePopover()
      enqueueSnackbar('Commission applied')
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [applyPsfMut, { isLoading: isLoadingApplyPsf }] = useMutation(applyPsf, {
    onSuccess: () => {
      handleClosePopover()
      enqueueSnackbar('Psf applied')
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [emailPayOffMut, { isLoading: isLoadingEmailPayOff }] = useMutation(emailPayOffLetter, {
    onSuccess: () => {
      handleClosePopover()
      enqueueSnackbar('Email pay off was sent')
    },
    onError: (err: AxiosError) => {
      if (err.response?.data.description === 'REPRESENTATIVE_NOT_FOUND') enqueueSnackbar('Representatives are not found within the current deal')
      else enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [zeroBalanceMut, { isLoading: isLoadingZeroBalance }] = useMutation(zeroBalanceLetter, {
    onSuccess: () => {
      handleClosePopover()
      enqueueSnackbar('Zero balance letter was sent')
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [readyToFundEmailMut, { isLoading: isLoadingReadyToFundEmail }] = useMutation(sendReadyToFundEmail, {
    onSuccess: () => {
      refetch && refetch()
      handleClosePopover()
      enqueueSnackbar('The Ready To Fund letter was sent')
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [prepFundingEmailMut, { isLoading: isLoadingPrepFundingEmail }] = useMutation(sendPrepFundingEmail, {
    onSuccess: () => {
      refetch && refetch()
      handleClosePopover()
      enqueueSnackbar('The Prep Funding letter was sent')
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [sendPayHistoryToEmailMut, { isLoading: isLoadingSendHistoryToEmail }] = useMutation(sendPayHistoryToEmail, {
    onSuccess: () => {
      handleClosePopover()
      enqueueSnackbar('Pay History successfully sent')
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [downloadPayHistoryCondensed, { isLoading: isLoadingPayHistoryCondensed }] = useMutation(exportDealPayHistoryCondensed, {
    onSuccess: (data) => {
      handleClosePopover()
      downloadFile(data)
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [downloadPayHistoryMut, { isLoading: isLoadingPayHistory }] = useMutation(exportDealPayHistory, {
    onSuccess: (data) => {
      handleClosePopover()
      downloadFile(data)
    },
    onError: () => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [extendDealMut, { isLoading: isLoadingExtendDeal }] = useMutation(extendDeal, {
    onSuccess: (data, variables) => {
      refetch && refetch()
      setOpenExtendDeal(false)
      handleClosePopover()
      enqueueSnackbar('Deal was extended')
      if (variables.type === ExtendDealType.reverse) {
        queryCache.fetchQuery(['reverseSchedulesDeal', id], () => getReverseSchedulesByDeal(id), {
          retry: false,
          cacheTime: 0,
          enabled: id,
        })
      }
    },
    onError: (err: AxiosError) => {
      if (err.response?.data.description === 'EXTEND_TYPE_DOESNT_MATCH') {
        enqueueSnackbar('This deal has another extend type')
      } else if (err.response?.data.description === 'INVALID_FLEX_AMOUNT') {
        enqueueSnackbar('Extend amount is more than remain to extend')
      } else {
        enqueueSnackbar(SOMETHING_WENT_WRONG)
      }
    },
  })

  const [refiDealMut, { isLoading }] = useMutation((values: string[]) => refiDeal(values), {
    onSuccess(resData) {
      push(generatePath(INHOUSE_DEAL, { view: 'edit', type: 'inhouse', step: 1, id: resData.data.id }))
    },
  })

  const [clawbackComplete, { isLoading: isLoadingClawback }] = useMutation(repClawback, {
    onSuccess: () => {
      handleClosePopover()
      enqueueSnackbar('REP Clawback was sent')
      if (deal) {
        deal.repClawbackSent = true
      }
    },
    onError: (err: AxiosError) => {
      if (err.response?.data.description === 'CLAWBACK_ALREADY_SENT') {
        enqueueSnackbar('REP Clawback for this deal was already sent')
      } else {
        enqueueSnackbar(SOMETHING_WENT_WRONG)
      }
    },
  })

  const [returnClawbackComplete, { isLoading: isLoadingReturnClawback }] = useMutation(returnRepClawback, {
    onSuccess: () => {
      handleClosePopover()
      enqueueSnackbar('REP Clawback was returned')
      if (deal) {
        deal.repClawbackSent = false
      }
    },
    onError: (err: AxiosError) => {
      enqueueSnackbar(SOMETHING_WENT_WRONG)
    },
  })

  const [isoClawbackComplete, { isLoading: isLoadingIsoClawback }] = useMutation(isoClawback, {
    onSuccess: () => {
      handleClosePopover()
      enqueueSnackbar('ISO Clawback was sent')
      if (deal) {
        deal.isoClawbackSent = true
      }
    },
    onError: (err: AxiosError) => {
      if (err.response?.data.description === 'CLAWBACK_ALREADY_SENT') {
        enqueueSnackbar('ISO Clawback for this deal was already sent')
      } else if (err.response?.data.description === 'ISO_NOT_FOUND') {
        enqueueSnackbar('There is no ISO for the current deal')
      } else {
        enqueueSnackbar(SOMETHING_WENT_WRONG)
      }
    },
  })

  const [returnIsoClawbackComplete, { isLoading: isLoadingReturnIsoClawback }] = useMutation(returnIsoClawback, {
    onSuccess: () => {
      handleClosePopover()
      enqueueSnackbar('ISO Clawback was returned')
      if (deal) {
        deal.isoClawbackSent = false
      }
    },
    onError: (err: AxiosError) => {
      if (err.response?.data.description === 'ISO_NOT_FOUND') {
        enqueueSnackbar('There is no ISO for the current deal')
      } else {
        enqueueSnackbar(SOMETHING_WENT_WRONG)
      }
    },
  })

  const [getRefiDealMut, { isLoading: isLoadingGetDealsForRefi }] = useMutation(() => getRefiDeals(deal?.clientId || 0), {
    onSuccess(resData) {
      if (resData.data.length > 1) {
        setOpenSelectRefiDeals(resData.data)
      } else {
        refiDealMut([resData.data[0].id.toString()])
      }
    },
  })

  return (
    <Popover
      classes={{ paper: s.popover }}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...rest}
    >
      {hasPermission(DEAL_EMAIL_PAY_HISTORY_PERM) && (
        <MenuItem
          className={s.menuItem}
          disabled={isLoadingSendHistoryToEmail || disableEmailPayHistory}
          onClick={() => {
            sendPayHistoryToEmailMut(id)
          }}
        >
          <Box className={s.iconContainer}>
            <EmailPayHistoryIcon />
          </Box>
          <Typography variant="h4" color="textSecondary">
            Email Pay History
          </Typography>
        </MenuItem>
      )}
      {hasPermission(DEAL_DOWNLOAD_PAY_HISTORY_PERM) && (
        <MenuItem
          className={s.menuItem}
          disabled={isLoadingPayHistory || disableDownloadPayHistory}
          onClick={() =>
            downloadPayHistoryMut({
              dealId: id,
            })
          }
        >
          <Box className={s.iconContainer}>
            <DownloadPayHistoryIcon />
          </Box>
          <Typography variant="h4" color="textSecondary">
            Download Pay History Details
          </Typography>
        </MenuItem>
      )}
      {hasPermission(DEAL_PAY_HISTORY_DETAILS_CONDENSED) && (
        <MenuItem
          className={s.menuItem}
          disabled={isLoadingPayHistoryCondensed || disableDownloadPayHistoryCondensed}
          onClick={() => {
            downloadPayHistoryCondensed({
              dealId: id,
            })
          }}
        >
          <Box className={s.iconContainer}>
            <DownloadPayHistoryIcon />
          </Box>
          <Typography variant="h4" color="textSecondary">
            Download Pay History Details Condensed
          </Typography>
        </MenuItem>
      )}
      {hasPermission(DEAL_EMAIL_PAYOFF_LETTER_PERM) && (
        <MenuItem className={s.menuItem} disabled={disableEmailPayOff || isLoadingEmailPayOff} onClick={() => emailPayOffMut(id)}>
          <Box className={s.iconContainer}>
            <EmailPayOffLetterIcon />
          </Box>
          <Typography variant="h4" color="textSecondary">
            Email PayOff Letter
          </Typography>
        </MenuItem>
      )}
      {hasPermission(DEAL_EMAIL_PAYOFF_LETTER_PERM) && (
        <MenuItem className={s.menuItem} disabled={disableZeroBalance || isLoadingZeroBalance} onClick={() => zeroBalanceMut(id)}>
          <Box className={s.iconContainer}>
            <EmailPayOffLetterIcon />
          </Box>
          <Typography variant="h4" color="textSecondary">
            Zero Balance Letter
          </Typography>
        </MenuItem>
      )}
      {hasPermission(DEAL_PREP_FUNDING_EMAIL_PERM) && (
        <MenuItem className={s.menuItem} disabled={isLoadingPrepFundingEmail || disablePrepFundingEmail} onClick={() => prepFundingEmailMut(id)}>
          <Box className={s.iconContainer}>
            <PrepFundingEmailIcon />
          </Box>
          <Typography variant="h4" color="textSecondary">
            Prep Funding Email
          </Typography>
        </MenuItem>
      )}
      {hasPermission(DEAL_READY_TO_FUND_PERM) && (
        <MenuItem className={s.menuItem} disabled={isLoadingReadyToFundEmail || disableReadyToFund} onClick={() => readyToFundEmailMut(id)}>
          <Box className={s.iconContainer}>
            <ReadyToFundIcon />
          </Box>
          <Typography variant="h4" color="textSecondary">
            Ready to Fund
          </Typography>
        </MenuItem>
      )}
      {hasPermission(DEAL_REFI_PERM) && (
        <MenuItem onClick={() => getRefiDealMut()} disabled={isLoading || disableRefi || isLoadingGetDealsForRefi} className={s.menuItem}>
          <Box className={s.iconContainer}>
            <REFIIcon />
          </Box>
          <Typography variant="h4" color="textSecondary">
            REFI
          </Typography>
        </MenuItem>
      )}
      {deal?.type === DealsType.outhouse && (
        <>
          {hasPermission(APPLY_COMMISSION_PERM) && (
            <MenuItem className={s.menuItem} disabled={isLoadingApplyCommission} onClick={() => applyCommissionMut(id)}>
              <Box className={s.iconContainer}>
                <ReadyToFundIcon />
              </Box>
              <Typography variant="h4" color="textSecondary">
                Apply Commission
              </Typography>
            </MenuItem>
          )}
          {hasPermission(APPLY_PSF_PERM) && (
            <MenuItem className={s.menuItem} disabled={isLoadingApplyPsf} onClick={() => applyPsfMut(id)}>
              <Box className={s.iconContainer}>
                <ReadyToFundIcon />
              </Box>
              <Typography variant="h4" color="textSecondary">
                Apply Psf
              </Typography>
            </MenuItem>
          )}
        </>
      )}
      {hasPermission(DEAL_EXTEND_PERM) && (
        <MenuItem
          onClick={() => {
            setOpenExtendDeal(true)
          }}
          disabled={isLoading || disableExtend}
          className={s.menuItem}
        >
          <Box className={s.iconContainer}>
            <ExtendIcon />
          </Box>
          <Typography variant="h4" color="textSecondary">
            Extend Deal
          </Typography>
        </MenuItem>
      )}
      {hasPermission(DEAL_CLAWBACK_PERM) && (
        <MenuItem
          onClick={() => {
            if (deal?.repClawbackSent) {
              returnClawbackComplete(id)
            } else {
              clawbackComplete(id)
            }
          }}
          disabled={disableClawback || isLoadingClawback || isLoadingReturnClawback}
          className={s.menuItem}
        >
          <Box className={s.iconContainer}>
            <ReturnIcon />
          </Box>
          <Typography variant="h4" color="textSecondary">
            {deal?.repClawbackSent ? 'Return REP Clawback' : 'REP Clawback'}
          </Typography>
        </MenuItem>
      )}
      {hasPermission(DEAL_CLAWBACK_PERM) && (
        <MenuItem
          onClick={() => {
            if (deal?.isoClawbackSent) {
              returnIsoClawbackComplete(id)
            } else {
              isoClawbackComplete(id)
            }
          }}
          disabled={disableIsoClawback || isLoadingIsoClawback || isLoadingReturnIsoClawback}
          className={s.menuItem}
        >
          <Box className={s.iconContainer}>
            <ReturnIcon />
          </Box>
          <Typography variant="h4" color="textSecondary">
            {deal?.isoClawbackSent ? 'Return ISO Clawback' : 'ISO Clawback'}
          </Typography>
        </MenuItem>
      )}
      {hasPermission(MOVE_TO_DOCUSIGN) && (
        <MenuItem
          onClick={() => {
            setMoveToDocusignLoading(true)

            if (!deal?.id) {
              return
            }

            moveToDocusign(deal.id)
              .then(() => {
                setMoveToDocusignLoading(false)
                enqueueSnackbar('Email was successfully sent')

                handleClosePopover()
              })
              .catch((err: AxiosError) => {
                setMoveToDocusignLoading(false)
                if (err.response?.data.description === 'NO_SIGNERS_FOR_CONTRACT') {
                  enqueueSnackbar('There are no owners for this client')
                } else {
                  enqueueSnackbar('Something went wrong')
                }
              })
          }}
          disabled={disableMoveToDocusign || moveToDocusignLoading}
          className={s.menuItem}
        >
          <Box className={s.iconContainer}>
            <DocumentIconOutlined />
          </Box>
          <Typography variant="h4" color="textSecondary">
            Docusign
          </Typography>
        </MenuItem>
      )}
      {topBlockPerms.some((val: string) => hasPermission(val)) && <Divider classes={{ root: s.dividerRoot }} />}
      {hasPermission(DEAL_COMMENTS_LOG_PERM) && (
        <MenuItem onClick={() => push(generatePath(DEAL_COMMENTS_LOG_URL, { id }))} className={s.menuItem}>
          <Box className={s.iconContainer}>
            <CommentsLogIcon />
          </Box>
          <Typography variant="h4" color="textSecondary">
            Comments Log
          </Typography>
        </MenuItem>
      )}
      {hasPermission(DEAL_AUDIT_LOG_PERM) && (
        <MenuItem onClick={() => push(generatePath(DEAL_AUDIT_LOG_URL, { id }))} className={s.menuItem}>
          <Box className={s.iconContainer}>
            <AuditLogIcon />
          </Box>
          <Typography variant="h4" color="textSecondary">
            Audit Log
          </Typography>
        </MenuItem>
      )}
      <ModalComponent open={openExtendDeal}>
        <ExtendDealForm
          initValues={{
            status: deal?.status,
            paybackAmount: deal?.payback,
            remaining: deal?.remaining,
            net: deal?.net,
            type: '',
            amount: '',
          }}
          submit={(values) => {
            return extendDealMut({
              id: id,
              ...values,
            })
          }}
          cancel={() => {
            handleClosePopover()
            setOpenExtendDeal(false)
          }}
          isLoading={isLoadingExtendDeal}
        />
      </ModalComponent>
      <ModalComponent open={openSelectRefiDeals.length > 0}>
        <SelectRefiDealsForm
          initValues={{
            selectedDeals: openSelectRefiDeals,
            dealsIds: {},
          }}
          submit={async (values) => {
            const ids = Object.keys(values.dealsIds).filter((key: string) => values.dealsIds[key])

            await refiDealMut(ids)
            handleClosePopover()
            setOpenSelectRefiDeals([])
          }}
          cancel={() => {
            handleClosePopover()
            setOpenSelectRefiDeals([])
          }}
          isLoading={isLoading}
        />
      </ModalComponent>
    </Popover>
  )
}

export default DetailsPopover
