export const INVALID_LOGIN_ATTEMPT = 'INVALID_LOGIN_ATTEMPT'
export const INVALID_RESET_PASSWORD_ATTEMPT = 'INVALID_RESET_PASSWORD_ATTEMPT'
export const ACCOUNT_NOT_ACTIVATED = 'ACCOUNT_NOT_ACTIVATED'
export const INVALID_VALIDATION_TOKEN = 'INVALID_VALIDATION_TOKEN'
export const INVALID_LIMIT_VALUE = 'INVALID_LIMIT_VALUE'
export const YOU_DONT_HAVE_ENOUGHT = "You don't have enough costs on your balance"
export const REQUIRED_FIELD = 'Required field'
export const INVALID_PHONE_NUMBER = 'Invalid phone number'
export const INVALID_MOBILE_NUMBER = 'Invalid mobile phone number'
export const INVALID_FEDERAL_TAX_ID = 'Invalid federal tax id'
export const INVALID_SYMBOLS = 'Invalid symbols'
export const INVALID_EMAIL = 'Invalid email'
export const SELECT_COMPANY = 'Please select company from list'
export const SOMETHING_WENT_WRONG = 'Something went wrong'
export const USER_ROLE_ALREADY_EXIST = 'User role with this name already exists'
export const INVALID_PRICE_FORMAT = 'Invalid price format'
export const ZIP_CODE_FORMAT_ERROR = 'Zip code must be in format xxxxx or xxxxx-xxxx'
export const BANK_ROUTING_MUST_BE_NINE_SYMBOLS = 'Bank routing number must be 9 digits.'
export const BANK_COMPANY_MUST_BE_AT_MOST_NINE_SYMBOLS = 'Bank company ID must be at most 9 digits.'
export const NUMBERS_ONLY = 'Numbers only'
export const ONLY_DIGITS = 'Only digits'
export const MIN_VALUE_IS_ONE = 'Minimum value is 1'
export const MIN_VALUE_IS = 'Minimum value is :value'
export const MAX_VALUE_IS = 'Maximum value is :value'
export const NUMBER_CANNOT_BE_NEGATIVE = 'Field cannot be negative'
export const COMISSION_NEED_TO_BE_LOWER_THAN_15_PERCENT = 'Commission fee needs to be lower than 15% of Advance amount'
export const COMISSION_NEED_TO_BE_LOWER_THAN_20_PERCENT = 'Commission fee needs to be lower than 20% of Advance amount'
export const FLEX_NEED_TO_BE_LOWER_THAN_ADVANCED_AMOUNT = "Flex/Reverse Amount can't be more than Advance Amount"
export const USER_EXISTS = 'The user with the current email already exists'
export const INVALID_PERCENTAGE = 'Invalid percentage format'
export const NULL_PERCENTAGE = 'Syndicator must have more than 0% participation'
export const FROM_VALUE_MUST_BE_LESS_THAN_TO = 'From value has to be less than to'
export const TO_VALUE_MUST_BE_MORE_THAN_FROM = 'To value has to be more than from'
export const MUST_BE_INTEGER = 'Must be integer'
export const ONLY_LETTERS = 'Only letters'
export const FIELD_MUST_CONTAIN_THREE_SYMBOLS = 'Field must contain 3 symbols'
export const INVALID_MANAGEMENT_FEE_VALUE = 'Invalid management fee value'
export const BANK_ACCOUNT_ALREADY_USED = 'The user with the same bank account already exists'
export const LENDER_DONT_HAVE_ENOUGHT_MONEY =
  'Funds are not available for this syndicator for this amount. Please adjust amount or select a different syndicator'
export const VERIFICATION_NOT_COMPLETED = 'Vefirication not completed. Please complete the verification before generating report'
export const SPLIT_COMMISSION_ERROR = 'Split commission for Representatives'
export const NO_PERMISSIONS_FOR_THIS_DEAL = "You don't have permission to see this deal"
export const CLIENT_WITH_BUSINESS_NAME_EXISTS = 'Client with this business name already exists'
export const CLIENT_WITH_EMAIL_EXISTS = 'Client with this email already exists'
export const CLIENT_WITH_BUSINESS_PHONE_EXISTS = 'Client with this business phone number already exists'
